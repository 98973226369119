import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import project from './project'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user,
    project
  }
}
)

export default store

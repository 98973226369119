import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import $cookies from 'vue-cookies';

import axios from 'axios';
import VueAxios from 'vue-axios';
Vue.use(VueAxios,axios);

// axios.interceptors.request.use(config => {
//   if ()
//   if (!$cookies.get('bc_auth')) {
//     router.push({name: 'login'})
//   }
//   return config
// }, error => {
    
// })

Vue.config.productionTip = false

Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

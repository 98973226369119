<template>
  <div id="app" >
    <el-container class="container">
      <el-header class="nav-header">
        <nav-head></nav-head>
      </el-header>
      <el-main class="main">
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import Header from './components/common/nav-header.vue'
export default {
  name: 'App',
  components: {
    "nav-head": Header,
  },
  mounted() {
    // this.$router.push({name: "index"})
  },
  methods: {
    loadData() {
      console.log("loadData  index  *************")
    }
  }
}
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  width: 100vw;
  min-height: 100vh;
  background-color: #f7f8fa;

  .container {
    width: 100%;


    .nav-header {
      padding: 0;
      height: auto !important;
      
    }

    .main {
      
      margin: auto;
      max-width: 1400px;
      min-width: 800px;
      width: 75%;
      padding: 0;
      
    }
  }
}

</style>
import axios from 'axios';

const state = {
  projectList: [],
  has_next_page: true,
  showForm: false,
  checkInfo: false,
  currentId: 0,
  currentProvince: {},
  currentCity: {},
  currentFirstCategory: {},
  currentSecondCategory: {},
  loading: false,
  skillPage: 1,
  skillHasNext: true,
  currentHeight: 0,
  searchKey: '',
}

const getters = {
  getSearchKey(state) {
    return state.searchKey
  }
}

const setters = {
  setSearchKey(state, value) {
    return state.searchKey = value
  }
}
  
const mutations = {
  setSearchKey(state, value) {
    state.searchKey = value
  },
  setCurrentHeight(state, value) {
    state.currentHeight = value
  },
  selectProvince(state, value) {
    state.currentProvince = value
  },
  selectCity(state, value) {
    state.currentCity = value
  },
  selectFirstCategory(state, value) {
    state.currentFirstCategory = value
  },
  selectSecondCategory(state, value) {
    state.currentSecondCategory = value
  },
  close_drawer(state, value) {
    state.showForm = false
  },
  check(state, value) {
    state.currentId = value
  },
  close_check_drawer(state, value) {
    state.checkInfo = false
  }
}

const actions = {
  
  searchProjects({state}, data) {
   
    if (data && data.refresh) {
      state.skillPage = 1
      state.skillHasNext = true
      state.projectList = []
    }
    if (state.skillHasNext) {
      state.loading = true
      return axios({
        url: '/nrb_service/skill/get_skill_list',
        method: 'get',
        params: {
          search_key: state.searchKey || '',
          province_id: state.currentProvince.id || '',
          city_id: state.currentCity.id || '',
          skill_type_id: state.currentSecondCategory.id || '',
          page: state.skillPage,
        }
      }).then(res => {
        state.loading = false
        if (res.data && res.data.code === 'A00000') {
          state.projectList.push(...res.data.skill) 
          state.skillHasNext = res.data.has_next_page
          state.skillPage += 1      
        }
      })
    }
    
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
<template>
  <div>
    <div class="login" type="primary" v-if="!isLogin && !isLoginPage" @click="toLogin" size="mini">你好，请登录</div>
    <div v-if="isLogin" class="mine-box">
      <div class="avartar" @click="toUserInfo">
        <i class="el-icon-user-solid" style="cursor: pointer;"></i>
      </div>
      <div class="mine" @click="toUserInfo">我的</div>
      <div class="logout" @click="logout">退出登录</div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
  data() {
    return {}
  },
  computed: {
    ...mapState({
      isLogin: (state) => state.user.isLogin
    }),
    isLoginPage() {
      return this.$route.name === 'login'
    }
  },
  mounted() {
    if (this.isLogin) {
      this.$store.dispatch("user/getUserInfo")
    }
    
  },
  methods: {
    toLogin() {
      
      this.$router.push({name: "login"})
    },
    toUserInfo() {
      this.$router.push({name: "user"})
    },
    logout() {
      this.$store.dispatch("user/logout")
      location.reload()
    }
  }

}
</script>

<style lang="scss" scoped>
.avartar {
  cursor: pointer;
  font-size: 21px;
  width: 21px;
  height: 21px
}

.mine-box {
  cursor: pointer;
  color: orange;
  display: flex;
  line-height: 21px;
}
.mine {

  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
}

.login {
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
}

.login:hover {
  color: #409EFF;
}

.logout {
  cursor: pointer;
  font-size: 15px;

  margin-left: 10px;
  color: rgb(148, 148, 148);
}
</style>>

import Vue from 'vue'
import VueRouter from 'vue-router'
import $cookies from 'vue-cookies';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    name: 'index',
    component: () => import( '../views/project.vue')
  },
  {
    path: '/project',
    name: 'project',
    component: () => import( '../views/project.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import( '../views/detail.vue')
  },
  {
    path: '/user',
    name: 'user',
    component: () => import( '../views/user.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import( '../views/login.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  console.log("to*************************")
  console.log(to)
  console.log("from*************************")
  console.log(from)
  let filter = ['user']
  if (filter.indexOf(to.name) >= 0 && !$cookies.get('nrb_service_auth')) {
    next("/login")
  } else {
    next()
  }
})

export default router
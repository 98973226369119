<template>
  <div class="header-box">
    <div class="row">
      <div ><l-logo></l-logo></div>
      <div class="operation">
        <l-operation></l-operation>
      </div>
      <div><l-user class="user"></l-user></div>
    </div>
  </div>
</template>

<script>
import Logo from "./logo.vue"
import operation from "./operation.vue"
import User from "./user.vue"

export default {
  name: "header",
  components: {
    "l-logo": Logo,
    "l-user": User,
    "l-operation": operation,
    
  }
}
</script>

<style lang="scss" scoped>
  .header-box {
    background-color: white;
  }
 .row {
    margin: auto;
    max-width: 1400px;
    min-width: 800px;
    width: 75%;

    display: flex;
    

   .operation {
     padding: 15px 0;
     flex: 1;
     margin: 0 20px;
     display: flex;
     line-height: 100%;
    //  color: rgba(128, 128, 128, 0);
     
   }

   .category {
     margin-right: 20px;
   }

   .user {
     padding: 30px 0;
   }
 }
</style>>

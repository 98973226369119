<template>
  <div>
    <el-image src="http://www.nrb.net.cn/images/logo.png" class="logo" @click="toIndex"></el-image>
  </div>
</template>

<script>
export default {
  methods: {
    toIndex() {
      this.$router.push({name: "index"})
    }
  }
}
</script>


<style>
  .logo {
    cursor: pointer;
    font-size: 28px;
    width: 178px;
    height: 76px;
  }
</style>
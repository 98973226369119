import $cookies from 'vue-cookies';
import axios from 'axios';

const state = () => ({
    mobile: $cookies.get('nrb_service_auth'),
    isLogin: !!$cookies.get('nrb_service_auth'),
    user: {},
    editing: false,
    editingId: undefined,
    mySkillList: [],
    loading: false,
    skillPage: 1,
    skillHasNext: true,
})

const getters = {
    vue(state) {
        return this._vm;
    },
    currentUserInfo({state, dispatch}) {
        if (state.userInfo) {
            return state.userInfo;
        }
        dispatch('saveCurrentUserInfo').then(res => {
            return res
        })
    }
}
  
const mutations = {
    SET_EDIT_ID(state, value) {
        state.editingId = value;
    },
    SET_EDIT (state, value) {
        state.editing = value;
        
    },
    SET_TOKEN (state, value) {
        state.token = value;
        $cookies.set('token', value, 60 * 30);
    },
    SET_USER (state, value) {
        state.userInfo = value
        window.localStorage.setItem("userInfo", JSON.stringify(value));
    },
    REMOVE_TOKEN (state, value) {
        state.token = null;
        $cookies.remove('token');
    },
    REMOVE_USER (state, value) {
        state.userInfo = null;
        window.localStorage.removeItem("userInfo");
    },
    SET_IS_LOGIN (state, value) {
        state.isLogin = value;
    }
}

const actions = {
    logout({state}) {
        $cookies.remove('nrb_service_auth');
        state.isLogin = false
        state.mobile = undefined
        state.user = {}
    },
    loadMySkillList({state}, data) {
        console.log("加载我的技能列表")
        
        if (data && data.refresh) {
            state.skillPage = 1
            state.skillHasNext = true
            state.mySkillList = []
        }
        if (state.skillHasNext) {
            state.loading = true
            console.log(state.skillHasNext)
            return axios({
                url: '/nrb_service/skill/get_user_skills',
                method: 'get',
                params: {
                    user_id: state.user.id,
                    page: state.skillPage,
                }
                }).then(res => {
                    
                    state.loading = false
                    if (res.data && res.data.code === 'A00000') {
                        state.mySkillList.push(...res.data.skill) 
                        state.skillHasNext = res.data.has_next_page
                        state.skillPage += 1
                        
                        
                    }
            })
        }
        
    },
    getUserInfo({state}) {
        state.mobile = $cookies.get('nrb_service_auth')
        if (!state.mobile) {
            return
        }
        return axios({
            url: '/nrb_service/user/get_info',
            method: 'get',
            params: {
              login_from: 'web',
              mobile: state.mobile,
              
            }
          }).then(res => {
            if (res.data && res.data.code === 'A00000') {
              state.user = res.data
              
            }
          })
    },
    initLoginInfo({dispatch, commit, state}) {
        if (state.token !== null && state.token !== undefined) {
            commit("SET_IS_LOGIN", true)
            dispatch('saveCurrentUserInfo')
        }
    },
    login({commit, getters, dispatch}, {account, password}) {
        return this.$api.user.login(account, password).then(res => {
            if (res.status === 200 && res.data.code === 'SUCCESS') {
                dispatch("refreshLoginInfo", {token: res.headers.authorization})
                dispatch("message", {type: "success", message: '登录成功', duration: 1500}, {root: true})
                commit("SET_IS_LOGIN", true)
                return 'success'
            } else {
                dispatch("message", {type: "error", message: '登录失败', duration: 1500}, {root: true})
            }
        })
    },
    
    saveCurrentUserInfo({commit, dispatch}) {
        this.$api.user.getCurrentUserInfo().then(res => {
            if (res.status === 200 && res.data.code === 'SUCCESS') {
                commit('SET_USER', res.data.data)
                return res.data.data
            } else {
                dispatch('message', {type: "error", message: '系统繁忙，请稍后再试', duration: 1500}, {root: true})
            }
        })
    },
    refreshLoginInfo({commit, dispatch}, {token}) {
        commit('SET_TOKEN', token)
        dispatch('saveCurrentUserInfo')
    }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
<template>
  <div>
    <el-input v-if="showSearch" class="search-input" v-model="searchKey" @keyup.enter.native="doSearch" placeholder="请输入关键字" >
      
      <el-button type="primary" slot="append" @click="doSearch" icon="el-icon-search">搜索</el-button>
    </el-input>
  </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
  data() {
    return {
     searchKey: ''
    }
  },
  computed: {
    
    
    showSearch() {
      
      if (this.$route.name === 'index') {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    searchKey(val) {
      this.$store.commit("project/setSearchKey", val)
    }
  },
  methods: {
    doSearch() {
      this.$store.dispatch("project/searchProjects", {refresh: true})
    }
  },
  mounted() {
    
  }
}
</script>

<style lang="scss">
 .search-input {
   width: 600px !important;
   margin-left: 30px !important;
 }

 .search-input input.el-input__inner {
   height: 48px !important;
 }

 .search-input button.el-button--primary {
    color: #FFF !important;
    background-color: #409EFF !important;
    border-color: #409EFF !important;
 }

 .search-input div.el-input-group__append {
   background-color: #409EFF !important;
 }
</style>